var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltip(),
          expression: "tooltip()",
        },
      ],
      class: {
        selected: _vm.selected,
        "minimal-section-heading": true,
        center: _vm.hideTitleText,
      },
      on: {
        click: function ($event) {
          return _vm.selectSection(_vm.index)
        },
      },
    },
    [
      _vm.icon
        ? _c("Icon", {
            staticClass: "section-icon",
            attrs: { icon: _vm.icon, size: "small" },
          })
        : _vm._e(),
      !_vm.hideTitleText
        ? _c("h3", { staticClass: "section-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }