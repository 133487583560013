var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "minimal-home",
      style: _vm.getBackgroundImage() + _vm.setColumnCount(),
    },
    [
      _c(
        "div",
        { staticClass: "minimal-buttons" },
        [
          _c("ConfigLauncher", {
            staticClass: "config-launcher",
            on: { modalChanged: _vm.updateModalVisibility },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "title-and-search" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("h1", [_vm._v(_vm._s(_vm.pageInfo.title))]),
          ]),
          _c("MinimalSearch", {
            ref: "filterComp",
            attrs: { active: !_vm.modalOpen },
            on: {
              "user-is-searchin": (s) => {
                this.searchValue = s
              },
            },
          }),
        ],
        1
      ),
      _vm.checkTheresData(_vm.sections)
        ? _c(
            "div",
            {
              class: `item-group-container ${
                !_vm.tabbedView ? "showing-all" : ""
              }`,
            },
            [
              _vm._l(_vm.sections, function (section, index) {
                return _c("MinimalHeading", {
                  key: `heading-${index}`,
                  staticClass: "headings",
                  attrs: {
                    index: index,
                    title: section.name,
                    icon: section.icon,
                    selected: _vm.selectedSection === index,
                    hideTitleText: _vm.sections.length > 8,
                  },
                  on: { sectionSelected: _vm.sectionSelected },
                })
              }),
              _vm._l(_vm.sections, function (section, index) {
                return _c("MinimalSection", {
                  key: `body-${index}`,
                  attrs: {
                    index: index,
                    title: section.name,
                    icon: section.icon || undefined,
                    groupId: _vm.makeSectionId(section),
                    items: _vm.filterTiles(section.items),
                    widgets: section.widgets,
                    selected: _vm.selectedSection === index,
                    showAll: !_vm.tabbedView,
                    itemSize: "small",
                  },
                  on: {
                    sectionSelected: _vm.sectionSelected,
                    itemClicked: function ($event) {
                      return _vm.finishedSearching()
                    },
                    "change-modal-visibility": _vm.updateModalVisibility,
                  },
                })
              }),
              _vm.checkIfResults()
                ? _c("div", { staticClass: "no-data" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchValue
                            ? _vm.$t("home.no-results")
                            : _vm.$t("home.no-data")
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "no-data" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.no-data")) + " "),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }