var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `minimal-section-inner ${_vm.selected ? "selected" : ""} ${
        _vm.showAll ? "show-all" : ""
      }`,
    },
    [
      _vm.items && (_vm.selected || _vm.showAll)
        ? _c(
            "div",
            { staticClass: "section-items" },
            [
              _vm._l(_vm.items, function (item) {
                return [
                  item.subItems
                    ? _c("SubItemGroup", {
                        key: item.id,
                        attrs: {
                          itemId: item.id,
                          title: item.title,
                          subItems: item.subItems,
                        },
                        on: { triggerModal: _vm.triggerModal },
                      })
                    : _c("Item", {
                        key: item.id,
                        attrs: {
                          item: item,
                          itemSize: _vm.itemSize,
                          parentSectionTitle: _vm.title,
                          isAddNew: false,
                          sectionDisplayData: _vm.displayData,
                        },
                        on: {
                          itemClicked: function ($event) {
                            return _vm.$emit("itemClicked")
                          },
                          triggerModal: _vm.triggerModal,
                        },
                      }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.widgets && _vm.selected && !_vm.showAll
        ? _c(
            "div",
            { staticClass: "minimal-widget-wrap" },
            _vm._l(_vm.widgets, function (widget, widgetIndx) {
              return _c("WidgetBase", {
                key: widgetIndx,
                attrs: { widget: widget, index: widgetIndx },
                on: { navigateToSection: _vm.navigateToSection },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.selected && !_vm.showAll && !_vm.widgets && _vm.items.length < 1
        ? _c("div", { staticClass: "empty-section" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("home.no-items-section")))]),
          ])
        : _vm._e(),
      _c("IframeModal", {
        ref: `iframeModal-${_vm.groupId}`,
        attrs: { name: `iframeModal-${_vm.groupId}` },
        on: {
          closed: function ($event) {
            return _vm.$emit("itemClicked")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }